import navigationData from "@locales/en/nav.json";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import { isUserAnOrganizationAdmin } from "@/src/utils/organization";

import { useAuth } from "./useAuth";
import { useCurrentLocale } from "./useCurrentLocale";

type NavigationName = keyof typeof navigationData;

export interface INavigation {
  name: NavigationName;
  href: string;
  current: boolean;
}

const initialNavigation: INavigation[] = [
  {
    name: "Organizations",
    href: "/organizations",
    current: false,
  },
  {
    name: "Jobs",
    href: "/jobs",
    current: false,
  },
  {
    name: "Members",
    href: "/members",
    current: false,
  },
  {
    name: "Events",
    href: "/events",
    current: false,
  },
  {
    name: "News",
    href: "/news",
    current: false,
  },
  {
    name: "WorkPride",
    href: "/workpride",
    current: false,
  },
  {
    name: "WorkFair",
    href: "/workfair",
    current: false,
  },
  {
    name: "Academy",
    href: "/academy",
    current: false,
  },
];

export interface IAccountDropdownItems {
  name: string;
  href: string;
}

export function useNavigation(): {
  navigation: INavigation[];
  userDropdownItems: IAccountDropdownItems[];
} {
  const pathname = usePathname();
  const locale = useCurrentLocale();
  const { authData } = useAuth();

  const [navigation, setNavigation] =
    useState<INavigation[]>(initialNavigation);

  useEffect(() => {
    const updatedNavigation = initialNavigation.map((item) => {
      if (item.name === "Organizations") {
        return {
          ...item,
          current:
            pathname.includes("/organizations") &&
            !pathname.includes("/create-event") &&
            !pathname.includes("/create-article"),
        };
      }
      if (item.name === "Jobs") {
        return {
          ...item,
          current:
            !pathname.includes("/organizations") && pathname.includes("/jobs"),
        };
      }
      if (item.name === "Members") {
        return {
          ...item,
          current: pathname.includes("/members"),
        };
      }
      if (item.name === "Events") {
        return {
          ...item,
          current:
            (pathname.includes("/event") &&
              !pathname.includes("/organizations")) ||
            pathname.includes("/create-event"),
        };
      }
      if (item.name === "News") {
        return {
          ...item,
          current:
            pathname.includes("/news") || pathname.includes("/create-article"),
        };
      }
      if (item.name === "WorkPride") {
        return {
          ...item,
          current:
            pathname.includes("/workpride") && !pathname.includes("/event"),
        };
      }
      if (item.name === "WorkFair") {
        return {
          ...item,
          current:
            pathname.includes("/workfair") && !pathname.includes("/event"),
        };
      }
      if (item.name === "Academy") {
        return {
          ...item,
          current: pathname.includes("/academy"),
        };
      }
      return item;
    });

    setNavigation(updatedNavigation);
  }, [pathname, locale]);

  let userDropdownItems: IAccountDropdownItems[] = [
    { name: "Home", href: "/profile/home" },
    { name: "View My Profile", href: `/members/${authData?.slug}` },
    { name: "Saved Jobs", href: "/profile/saved-jobs" },
    { name: "Connections", href: "/profile/connections" },
    { name: "Messages", href: "/profile/messages" },
    { name: "Settings", href: "/profile/settings" },
  ];

  if (
    authData?.isLegacyOrganizationAdmin ||
    isUserAnOrganizationAdmin(authData?.userTaxonomy)
  ) {
    userDropdownItems = [
      { name: "Home", href: "/profile/organization-profile" },
      {
        name: "View Organization Profile",
        href: `/organizations/${authData?.userTaxonomy.organization?.slug}`,
      },
      { name: "Settings", href: "/profile/settings" },
    ];
  }

  return { navigation, userDropdownItems };
}
